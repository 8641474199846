<template>
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100px" height="100px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" style="width:25px;fill:#fff" xml:space="preserve">
        <path d="M57,44.5c-1.5,0-2.7-1.2-2.7-2.7V24.5c0-1.5,1.2-2.7,2.7-2.7h17.6c1.5,0,2.7,1.2,2.7,2.7v17.3  c0,1.5-1.2,2.7-2.7,2.7H57z"/>
        <path d="M64.6,54.7L54.3,65.8c-0.6,0.6-0.6,1.7,0,2.3l10.3,11.1c0.7,0.7,1.8,0.7,2.5,0l10.3-11.1  c0.6-0.6,0.6-1.7,0-2.3L67.1,54.7C66.4,54,65.2,54,64.6,54.7z"/>
        <circle cx="33.8" cy="66.6" r="11.6"/>
        <path d="M23.6,25.7l8.9-5.1c0.8-0.5,1.8-0.5,2.7,0l8.9,5.1c0.8,0.5,1.3,1.4,1.3,2.3v10.2c0,0.9-0.5,1.8-1.3,2.3  l-8.9,5.1c-0.8,0.5-1.8,0.5-2.7,0l-8.9-5.1c-0.8-0.5-1.4-1.4-1.4-2.3V28C22.2,27,22.8,26.1,23.6,25.7z"/>
    </svg>
</template>

<script>
export default {
   name: 'SVGHome'
}
</script>

<style>

</style>